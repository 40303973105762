import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styles from "../styles/singleVariety.module.css"
import Layout from "./layout"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Textura from "../images/Textura.png"
import MaridajeLogo from "../images/singleVarietyImg/logos/maridajeLogo.svg"
import Linea from "../images/linealarga.svg"
import Slider from "react-slick"
import BeerMenuItem from "./beerMenuItem"
import BackPageArrow from "../images/backpagearrow.svg"
import { useMediaQuery } from "react-responsive"
import Header from "./header"
import NextArrowComp from "../components/nextArrowComp"
import PrevArrowComp from "../components/prevArrowComp"
import { navigate } from "@reach/router"
import SeeMore from "../images/+.svg"
// import SingleVarietyMobile from "../components/singleVarietyMobile"

import "../styles/singleVarietySlider.css"
// import { AiOutlineNumber } from "react-icons/ai"

const SingleVariety = ({ variedad, data, path }) => {
  const [active, setActive] = useState(false)
  /*   const [backImgMobile, setBackImgMobile] = useState(variedad.order) */
  const [pairingBackMobile, setPairingBackMobile] = useState(variedad.order)
  const [name, setName] = useState(data.allBeer.nodes[0].name.toUpperCase())
  const [description, setDescription] = useState()
  // const [grad, setGrad] = useState()
  // const [bitter, setBitter] = useState()
  // const [temp, setTemp] = useState()
  // const [pairing, setPairing] = useState()
  const [buyLink, setBuyLink] = useState("")
  const [num, setNum] = useState(0)

  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  // let elslider

  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
    adaptiveHeight: false,
    centerMode: false,
    className: styles.slickMain,
  }
  let swipeado = false

  let settingsMobile = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
    arrows: true,
    touchMove: true,
    centerMode: false,
    className: styles.test,
    fade: true,
    nextArrow: <NextArrowComp></NextArrowComp>,
    prevArrow: <PrevArrowComp></PrevArrowComp>,
    onSwipe: function (side) {
      swipeado = true
      let index
      if (side === "left") {
        if (num === sortedBeers.length - 1) {
          index = 0
        } else {
          index = num + 1
        }
      } else {
        if (num === 0) {
          index = sortedBeers.length - 1
        } else {
          index = num - 1
        }
      }
      setNum(index)
      // console.log("onSwipe",index)
      //   console.log(index,side)
      setPairingBackMobile(index)
      setName(data.allBeer.nodes[index].name.toUpperCase())
      setDescription(data.allBeer.nodes[index].description)
      // setGrad(data.allBeer.nodes[index].grad)
      // setBitter(data.allBeer.nodes[index].bitter)
      // setTemp(data.allBeer.nodes[index].temperature)
      // setPairing(data.allBeer.nodes[index].pairing)
      setBuyLink(data.allBeer.nodes[index].buyLink)
    },
    beforeChange: function (prev, act) {
      if (!swipeado) {
        let b = num
        // console.log("Previo",prev,"Actual",act)
        if (
          (prev < act && b != sortedBeers.length - 1) ||
          (prev === sortedBeers.length - 1 && act === 0)
        ) {
          if (b === sortedBeers.length - 1) {
            b = 0
          } else if (act === sortedBeers.length - 1) {
            b = sortedBeers.length - 1
          } else {
            b++
          }
        } else {
          if (b === 0 || act === sortedBeers.length - 1) {
            b = sortedBeers.length - 1
          } else {
            // console.log("menos")
            b--
          }
        }
        setNum(b)
        // console.log("before",b)
        setPairingBackMobile(b)
        setName(data.allBeer.nodes[b].name.toUpperCase())
        setDescription(data.allBeer.nodes[b].description)
        // setGrad(data.allBeer.nodes[b].grad)
        // setBitter(data.allBeer.nodes[b].bitter)
        // setTemp(data.allBeer.nodes[b].temperature)
        // setPairing(data.allBeer.nodes[b].pairing)
        setBuyLink(data.allBeer.nodes[b].buyLink)
      } else {
        swipeado = false
      }
      // console.log(inicio,b,c)
    },
  }
  let sortedEdges = data.allBeer.edges.sort(function (a, b) {
    return a.node.order - b.node.order
  })

  let sortedBeers = data.allBeer.nodes.sort(function (a, b) {
    return a.order - b.order
  })

  useEffect(() => {
    /*     setBackImgMobile(variedad.pairingBack) */
    setPairingBackMobile(variedad.order)
    setName(variedad.name.toUpperCase())
    setDescription(variedad.description)
    // setGrad(variedad.grad)
    // setBitter(variedad.bitter)
    // setTemp(variedad.temp)
    // setPairing(variedad.pairing)
    setBuyLink(variedad.buyLink)
    return () => {}
  }, [])

  function handleBack() {
    navigate("home/#varieties")
  }

  return (
    <>
      {!mobile ? (
        <BackgroundImage
          fluid={
            sortedEdges[variedad.order]?.node?.pairing_back_image?.childImageSharp
              .fluid ?? ""
          }
        >
          <Layout>
            <div className={styles.container}>
              <div
                style={{ backgroundImage: `url(${Textura})` }}
                className={styles.overlay}
              >
                <div
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-delay="100"
                  data-sal-easing="ease"
                  className={styles.textContainer}
                >
                  <div className={styles.beer}>
                    <div className={styles.backPage}>
                      <img alt="" src={BackPageArrow} />
                      <div className={styles.backPage} style={{ zIndex: 3 }}>
                        <img
                          alt=""
                          src={BackPageArrow}
                          id={
                            active ? styles.backArrow : styles.backArrowHidden
                          }
                        />
                        <Link
                          onMouseLeave={() => setActive(!active)}
                          onMouseOver={() => setActive(!active)}
                          // onClick={handleBack}
                          to={"/#varieties"}
                          style={{
                            textDecoration: "none",
                            color: "white",
                            backgroundColor: "transparent",
                            border: "none",
                            outline: "inherit",
                          }}
                        >
                          VOLVER
                        </Link>
                      </div>
                    </div>
                    {variedad.logo ? (
                      <img
                        alt="logo de variedad de cerveza"
                        src={variedad.logo}
                      />
                    ) : null}
                    <h1>{variedad.name.toUpperCase()}</h1>
                    <h3>{variedad.characteristics}</h3>
                    <h5>{variedad.description}</h5>
                    <button className={styles.button}>
                      {/* <a target="_blank" href={variedad.buyLink}> */}
                      <a
                        target="_blank"
                        href={variedad.buyLink}
                        onClick={() => {
                          window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Product",
                            event_action: "Content",
                            event_label: `Comprar`,
                            interaction: true,
                            component_name: "boton_comprar",
                            element_text: "Comprar",
                            product: `${variedad.name}`,
                          })
                        }}
                      >
                        COMPRAR
                      </a>
                    </button>
                  </div>

                  <div className={styles.grad}>
                    <img alt="" src={Linea} />
                    <div className={styles.gradContent}>
                      <div className={styles.gradSpanCont}>
                        <h3>{variedad.grad}%</h3>
                        <h5>GRADUACION ALCOHOLICA</h5>
                      </div>
                      <div className={styles.gradSpanCont}>
                        <h3>{variedad.bitter}</h3>
                        <h5>AMARGOR</h5>
                      </div>
                      <div className={styles.gradSpanCont}>
                        <h3>{variedad.temperature}°</h3>
                        <h5>TEMPERATURA IDEAL</h5>
                      </div>
                    </div>
                    <img alt="" src={Linea} />
                  </div>
                  <div className={styles.pairingContainer}>
                    <img
                      alt="logo de tabla con texto Disfrutala Con"
                      src={MaridajeLogo}
                    />
                    <div className={styles.pairing}>
                      <h6>
                        Se puede combinar a la perfección con platos como:
                      </h6>
                      <p>{variedad.pairing.toUpperCase()}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.menuContainer}>
                <h1 id={styles.ourVarieties}>NUESTRAS VARIEDADES</h1>

                <Slider className={styles.slider} {...settings}>
                  {data.allBeer.nodes.map(beer => {
                    return (
                      <BeerMenuItem
                        key={beer.name}
                        path={path}
                        beer={beer}
                      ></BeerMenuItem>
                    )
                  })}
                </Slider>
              </div>
            </div>
          </Layout>
        </BackgroundImage>
      ) : (
        <>
          <Header></Header>
          <Img
            fluid={
              data.allBeer.edges[pairingBackMobile].node
                .pairing_back_image_mobile.childImageSharp.fluid
            }
            className={
              active ? styles.mobileBackPairingReduce : styles.mobileBackPairing
            }
          ></Img>

          <div
            className={
              !active ? styles.mobileContainer : styles.mobileContainerHidden
            }
          >
            <Slider {...settingsMobile}>
              {sortedBeers.map((beer, idx) => {
                return (
                  <div key={beer.id} className={styles.textMobileContainer}>
                    <div>
                      <h1>{name}</h1>
                      <p>{description}</p>
                      <button className={styles.button}>
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          to={buyLink}
                          onClick={() => {
                            window.dataLayer.push({
                              event: "GAEvent",
                              event_category: "Product",
                              event_action: "Content",
                              event_label: `Comprar`,
                              interaction: true,
                              component_name: "boton_comprar",
                              element_text: "Comprar",
                              product: `${name}`,
                            })
                          }}
                        >
                          COMPRAR
                        </Link>
                      </button>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
          <div
            onClick={() => setActive(!active)}
            className={
              !active ? styles.seeMoreContainer : styles.seeMoreContainerActive
            }
          >
            <span>VER FICHA</span>
            <img alt="" src={SeeMore} />
            <div
              className={active ? styles.activeContainerMobile : styles.none}
            >
              <h1>{name.toUpperCase()}</h1>
              <div className={styles.grad}>
                <hr
                  style={{ marginBottom: "0px" }}
                  className={styles.textLine}
                ></hr>
                <div className={styles.gradContent}>
                  <div className={styles.gradSpanCont}>
                    <h3>{variedad.grad}%</h3>
                    <h5>GRADUACION ALCOHOLICA</h5>
                  </div>
                  <div className={styles.gradSpanCont}>
                    <h3>{variedad.bitter}</h3>
                    <h5>AMARGOR</h5>
                  </div>
                  <div className={styles.gradSpanCont}>
                    <h3>{variedad.temperature}°</h3>
                    <h5>TEMPERATURA IDEAL</h5>
                  </div>
                </div>
                <hr className={styles.textLine}></hr>
                <div className={styles.pairing}>
                  <h6>Se puede combinar a la perfección con platos como:</h6>
                  <p>{variedad.pairing.toUpperCase()}</p>
                </div>
              </div>
              <p id={styles.close}>CERRAR</p>
            </div>
          </div>
          {/*   </div> */}
        </>
      )}
    </>
  )
}

export default SingleVariety
