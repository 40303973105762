import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import styles from "./layout.module.css"
import "bootstrap/dist/css/bootstrap.min.css"
import SideMenu from "../components/sideMenu"
const Layout = props => {
  const [activeMenu, setActiveMenu] = useState(true)
  return (
    <>
      <div className={styles.content}>
        <Header />
        {props.children}

        <Footer />
      </div>
    </>
  )
}

export default Layout
