import React from "react"
import ArrowPrev from "../images/logos/arrowPrev.svg"
import { Link } from "gatsby"
function PrevArrow(props) {
  const { setCount, count, slideInfo } = props
  /* function prev() {
    if (count === 0) {
      setCount(slideInfo.length - 1)
    } else {
      setCount(count - 1)
    }
    console.log("slideInfo", slideInfo[count])
    console.log(count)
  }
 */
  const { className, style, onClick } = props

  return (
    <img
      className={className}
      style={{
        ...style,
        display: "block",
        width: "5.5%",
      }}
      onClick={onClick}
      src={ArrowPrev}
    />
  )
}

export default PrevArrow
